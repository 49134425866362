import { useEffect, useState } from "react"
import logopng from '../images/logopng.png'
import { NavigationsAdmin,  NavigationsmobileAdmin } from "./navigationadmin"
import axios from "axios"
import { emailstatus, sendotp, verifyotp } from "../../endpoints/apiurl"
import AnimteModal from "../modals/animateModal"
import Warningstatus from "../modals/warningstatus"
import OtpInput from "../modals/otpmodal"
import Settings from "./settings"
import BookingRecord from "./bookingRecords"
import DashboardHomeAdmin from "./dashboard"


const DashboardAdmin=()=>{
    const [onchangewidth,setonchangewidth]=useState(false)
    const [showpage,setshowpage]=useState('dashboard')
    const [showstatus,setshowstatus]=useState(false)
    const [showOTp,setshowOTp]=useState(false)
   

    const handlewidth=()=>{
        setonchangewidth(!onchangewidth)
    }

    const handleshowpage=(value)=>{
        console.log(value)
        setshowpage(value)
    }

    return(
        <>
        {<div className="w-screen h-screen overflow-hidden flex">
            <div className="md:block hidden md:w-44 lg:w-1/6 w-1/6 h-screen bg-slate-50 px-5 fixed z-50">
            <div>
                <div className="pt-5">
                    <img src={logopng} className="w-16 h-auto object-fit"/>
                <div className='potta-one-regular md:text-sm text-bluecolor text-xs'>HappyChild</div>
                </div>
                <div className="mt-10">
                <NavigationsAdmin
                handleshowpage={(value)=>handleshowpage(value)}
                />

                </div>
              
                
            </div>

            </div>
            <div className={`md:hidden block  z-50 fixed ${onchangewidth?'w-56':'w-16'} bg-slate-100 h-screen px-3 cursor-s-resize`}>
                <div onClick={handlewidth} className={`absolute top-1/2  ${onchangewidth?'left-52':'left-12'} `}><i className={`text-slate-400 opacity-70 fa fa-2x ${onchangewidth?'fa-arrow-circle-o-left':'fa-arrow-circle-o-right'}  `}></i></div>
            <div>
            <div className="flex items-center gap-3 pt-5">
                {!onchangewidth &&<i onClick={handlewidth} className="fa text-bluecolor fa-bars"></i>}
                {onchangewidth &&<div>
                    <img src={logopng} className="w-12 h-auto object-fit"/>
                    <div className='potta-one-regular md:text-sm text-bluecolor text-xs'>HappyChild</div>
                </div>}
                

            </div>
            <div className="pt-5">  
                <NavigationsmobileAdmin
                onchangewidth={onchangewidth}
                handleshowpage={(value)=>handleshowpage(value)}
                />
                </div>
            </div>
           

            </div>

            <div style={{backgroundColor:'#F7F7F7'}} className="md:flex-1 lg:ml-56 md:ml-44 ml-16 w-5/6 h-screen">
            {onchangewidth &&<div onClick={handlewidth} className="z-30 absolute w-full h-screen bg-slate-400 opacity-70"></div>}
               {showpage==='dashboard' &&<DashboardHomeAdmin/>}
               {showpage==='booking' &&<BookingRecord/>}
               {showpage==='settings' && <Settings/>}
            </div>
        </div>
}
        </>

    )
}
export default DashboardAdmin